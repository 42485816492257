<template>
  <main>
    <Navbar />

    <KoiBanner type="sub" :title="$t('FAQs')" />

    <router-view v-if="showZhContent" />

    <section v-else :class="[$style.sub, $style[$mq]]">
      <template v-for="(item, i) in $t('FAQCONTENT.Q')">
        <h6 :key="`Q-${i}`">{{ i + 1 }}. {{ item }}</h6>
        <template v-if="i === 3">
          <p
            v-for="(item, j) in $t('FAQCONTENT.A')[i]"
            :key="`A-${i}-${j}`"
            v-html="item"
          ></p>
        </template>
        <template v-else-if="Array.isArray($t('FAQCONTENT.A')[i])">
          <p v-for="(item, j) in $t('FAQCONTENT.A')[i]" :key="`A-${i}-${j}`">
            {{ item }}
          </p>
        </template>
        <p v-else :key="`A-${i}`">{{ $t("FAQCONTENT.A")[i] }}</p>
      </template>
    </section>

    <Zendesk />

    <KoiFooter />
  </main>
</template>

<script>
import debug from "debug";
import KoiBanner from "@/components/KoiBanner";
import KoiFooter from "@/components/KoiFooter";

import Navbar from "@/components/Navbar";
import Zendesk from "@/components/Zendesk";

const log = debug("koiweb");

export default {
  name: "faq",
  props: {},
  computed: {
    showZhContent() {
      const rn = this.$route.name;
      if (!this.$i18n.locale.startsWith("zh")) {
        return false;
      } else if (rn && rn.endsWith("-lang")) {
        return false;
      } else {
        return true;
      }
    }
  },
  components: {
    KoiBanner,
    KoiFooter,
    Navbar,
    Zendesk
  },
  mounted() {
    log("component (faq) mounted");
  }
};
</script>

<style module>
.sub {
  padding: 0 8vw 4vw;
  text-align: left;
  & h6 {
    border-top: 1px solid #cbcbcb;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: 3.6rem 0 0;
    padding: 3.6rem 0 2rem;
    &:first-child {
      border-top-color: transparent;
      margin-top: 2rem;
    }
  }
  & p {
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: justify;
  }
  &.xs,
  &.sm {
    & h6 {
      &:first-child {
        padding-top: 2rem;
      }
    }
  }
  &.md {
  }
  &.lg {
  }
  &.xl {
  }
}
</style>
